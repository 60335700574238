@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.wrapper {
    width: 600px;
    max-width: 100%;

    @media only screen and (max-width: $widthMobileMax){

    }

    &.trial {
        width: 900px;
    }

    .address-wrapper {     
    
        &>h4 {
            font-size: 16px;
            font-weight: 700;
            color: $colorGray60;
        }
    
        & .update-billing-btn {
            margin-top: -30px;
    
            @media only screen and (max-width: $widthMobileMax) {
                margin-top: 0;
            }
    
            &>button {
                min-width: 40px;
                width: 40px;
                height: 40px;
                border-radius: 8px;
    
                & svg>* {
                    stroke: $colorP1;
                }
            }
        }

        & .billing-address-form>div{
            margin-bottom: 20px !important;
        }

        & ul>li{
            margin: 2px 0;
        }
    
    }
}

.stripe-wrapper-modal {
	min-width: 500px;

	&__title {
		&>svg {
			flex-grow: 0;
			cursor: pointer;
		}
	}

	@media screen and (max-width: $widthMobileMax) {
		min-width: unset;
	}
}