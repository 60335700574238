@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';

.nav-menu {
	min-height: 73px;
	background-color: $colorPureWhite;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
	@media screen and (max-width: $widthTabletMax) {
		z-index: 99;
		bottom: 0;
		min-height: 66px;
		position: fixed;
		width: 100%;
		box-shadow: 0px -4px 24px rgb(0 0 0 / 7%);
		& > div > .nav-links-loggedin {
			width: 100%;
		}
		&.blanklayout {
			display: none !important;
		}
	}
	&.blankmobilelayout {
		@media screen and (max-width: $widthMobileMax) {
			display: none !important;
		}
	}

	& > div > .nav-links-mobile {
		width: 100%;

		& > a {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 5px;
			text-decoration: none;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: $colorGray40;
			min-width: 120px;
			text-align: center;
			@media screen and (max-width: 420px) {
				min-width: 100px;
			}
		}
	}
}

.bottom-nav-gap {
	height: 66px;
	background-color: transparent;
	&.blankmobilelayout {
		@media screen and (max-width: $widthMobileMax) {
			display: none !important;
		}
	}
}
.active-link {
	color: $colorP1 !important;
	& > svg {
		& path {
			stroke: #BB3A90;
		}
	}
}
.active-link-home {
	color: $colorP1 !important;
	& > svg {
		& > path {
			fill: $colorP1;
			stroke: #BB3A90;
		}
	}
}
