@import '@core/styles/_colors.scss';

.icon-button-wrapper {
	position: relative;
	background-color: transparent;
	border: none;
	outline: none;
	
	& > p {
    padding: 1px 1px;
    min-width: 14px;
    min-height: 14px;
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		background-color: $colorP1;
		color: $colorPureWhite;
		border: 1px solid #ffffff;
		border-radius: 2px;
		font-weight: 700;
		font-size: 10px;
		line-height: 12px;
	}
}
