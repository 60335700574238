@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';

.company-menu-wrapper {
	& > button {
		font-size: 14px;
		line-height: 17px;
		text-align: left;
		background-color: transparent;
		border: none;
		padding: 1.5rem 0;
		width: 301px;
		max-width: 100%;
		border-bottom: 1px solid $colorGray20;
		&:last-of-type {
			border-bottom: none;
		}
		& > svg {
			margin-right: 8px;
			& > * {
				stroke: black;
			}
		}
	}
	& > .active-menu {
		color: $colorAccent;
		font-size: 16px;
		font-weight: 700;
		& > svg {
			margin-right: 8px;
			& > * {
				stroke: $colorAccent;
			}
		}
	}
}
