@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.wrapper {
    height: 100%;
    .item-wrapper {
        margin-left: 30px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        .item-cat {
            color: $colorGray40;
            font-size: 14px;
            margin-bottom: 32px;
        }

        .item {
            margin-bottom: 30px;

            a {
                text-align: start;
                color: $colorGray70;
                font-size: 16px;

                &:hover {
                    color: $colorP1;
                    text-align: center;
                }
            }
        }
    }

    .icon svg path{
        stroke-width: 1;
    }

    p {
        max-width: 191px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: $colorGray50;
        text-align: center;
    }
}