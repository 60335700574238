@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.pop-badge {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 18px;
    background-color: $colorWarning40;
}

.period {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}

.price {
    text-align: center;
    font-size: 42px;
    font-weight: 900;
    margin-top: 6px;

    &.is-payment{
        & .orginal-price{
            color: $colorGray50 !important;
        }
    }

    & .duration {
        font-size: 14px;
    }

    & .discounted {
        color: $colorAlert30;

        & .symbol {
            font-size: 24px;
            font-weight: 700;
            padding-right: 5px;
        }

        & .orginal-price {
            color: $colorPureWhite;
            font-size: 20px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                border: 1px solid $colorAlert30;
                width: 76px;
                top: 48%;
            }
        }
    }
}

.save {
    font-size: 16px;
    font-weight: 700;
    color: $colorWarning30;
    margin-top: 6px;
    min-height: 24px;
    text-align: center;
    &.is-payment{
        &>span{
            color: $colorGray50 !important;
        }
    }
    &>span {
        color: $colorGray10;
    }
}