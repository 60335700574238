@import '@core/styles/colors.scss';

.home-page-title-loggedin {
	& > div:first-child {
		& > span {
			font-weight: 400;
			font-size: 14px;
			padding-bottom: 7px;
		}
		& > h1 {
			font-weight: 900;
			font-size: 24px;
			line-height: 29px;
			color: $colorP1;
		}
	}
	& > .icons {
		display: flex;
		align-items: center;
	}
}

.home-page-title-guest {
	& > svg {
		// width: 110px;
		min-width: 110px;
	}
	& > h1 {
		font-weight: 900;
		font-size: 24px;
		line-height: 29px;
		color: $colorP1;
	}
}

.active-cart-link{
	& > button {
		& > svg {
			stroke: $colorP1;
		}
	}
}