@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';

.nav-menu {
	min-height: 73px;
	background-color: $colorPureWhite;

	@media screen and (max-width: $widthTabletMax) {
		bottom: 0;
		min-height: 66px;
		position: fixed;
		width: 100%;
		box-shadow: 0px -4px 24px rgb(0 0 0 / 7%);

		&>div>.nav-links-loggedin {
			width: 100%;
		}
	}

	&>div>.nav-links-guest {

		&>a,
		&>button {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 5px;
			text-decoration: none;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: $colorGray40;
			text-transform: unset;
		}

		&>button:hover {
			background-color: transparent;
		}

		&>.about-menu li {
			margin: 0 24px 0 24px;
			padding: 24px 0 24px 0;
			border-bottom: 1px solid #0D0D0D1A;			

			&:hover {
				background-color: transparent !important;
			}
		}

		&>.about-menu li:last-child {
			border-bottom: none;
		}

		&>.about-menu>div {
			min-width: 360px;
			border-radius: 16px;
			
		}
		
		&>.about-menu{
			z-index: 4;
		}

		&>.explore-menu {
			margin-top: 20px !important;
			// margin: 0 auto;
			width: 100%;
			> div{
				border-radius: 15px;
				border: none;
				box-shadow: none;
				background-color: transparent;
			}
		}
	}

	&>div>div>.get-started {
		width: max-content;
		padding: 16px 24px;
		border-radius: 8px;
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		text-decoration: none;
	}
}


.active-link {
	color: $colorP1 !important;
}

.get-started {
	width: max-content;
	padding: 16px 24px;
	border-radius: 8px;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	text-decoration: none;
}

[data-theme="dark"] .nav-menu {
	&>div>.nav-links-guest {

		&>a,
		&>button {
			color: $colorPureWhite;
		}
	}
}

[data-theme="dark"] .get-started {
	color: $colorPureWhite;
	background-color: $colorP1;

	&.login {
		background-color: inherit;
	}

}


.nav-main-menu {
	padding: 16px 0;
	background-color: $colorSecondaryLight;
	>div>a {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		margin-right: 40px;
		color: $colorGray50;
		text-decoration: none;
		>span{
			font-weight: 700;
		}
	}
}