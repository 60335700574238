@import '@core/styles/_colors.scss';

.header-desktop{
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 999;
  flex-grow: 0;
}

[data-theme="dark"] .header-desktop nav{
  background-color: $backgroundDark;
}