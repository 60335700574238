@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.tab {
    display: flex;
    justify-content: space-between;
    max-width: 240px !important;
    padding: 0 !important;
    position: relative;
    background-color: $colorPureWhite;
    border-radius: 12px;
    padding: 0;
    font-weight: 700;
    font-size: 14px;
    color: $colorGray20;
    margin-top: 15px !important;
    box-shadow: 0px 12px 24px 0px rgba(211, 86, 146, 0.20);

    &>div {
        padding: 14px 38px 14px 28px;
        cursor: pointer;
        z-index: 2;
    }

    &::before {
        content: "";
        width: 130px;
        height: 49px;
        top: 0;
        right: 0;
        position: absolute;
        transition: all 0.5s;
        border-radius: 12px;
        background-color: $colorSecondaryLecture;
        z-index: 0;
    }

    &.left::before {
        transform: translateX(-110px);

    }

    &.right::before {
        transform: translateX(0);

    }

    & .selected {
        color: $colorPureWhite;

    }
}