@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.wrapper {
    width: 600px;
    max-width: 100%;

    &.trial {
        width: 900px;
    }

    & h3 {
        font-size: 16px;
        font-weight: 700;
    }

    & p {
        font-size: 14px;
        font-weight: 400;
        color: $colorGray40;
    }    

    & .verify-name-checkbox {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 35px;
        margin-bottom: 26px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 14px;
        line-height: 17px;
        color: $colorGray70;

        & p {
            margin-top: 4px;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
        }

        &>input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        &>span {
            position: absolute;
            top: calc(50% - 12px);
            left: 0;
            height: 24px;
            width: 24px;
            background-color: $colorPureWhite;
            border-radius: 8px;
            border: 1px solid $colorGray30;

            &:hover {
                background-color: #ccc;
            }

            &::after {
                content: '';
                position: absolute;
                display: none;
                left: 9px;
                top: 4px;
                width: 6px;
                height: 12px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        &>input:checked+span {
            border: unset;
            background-color: $colorP1;
            color: $colorP1;

            &::after {
                display: block;
            }
        }
    }


}