@import '@core/styles/colors.scss';

.textinput-wrapper {
	margin : 0 !important;
	& > label {
		margin: 8px 0px;
		color: $colorGray70;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		padding: 0;
	}
	& > input {
		height: 40px;
		padding: 8px 12px;
		margin-bottom: 8px;
		background-color: $colorPureWhite;
		color: $colorGray70;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		border: 1px solid $colorGray20;
		border-radius: 8px;
		outline: none;
		&::placeholder {
			color: $colorGray30;
		}
		&:focus {
			border: 2px solid $colorP1;
		}
		&:-webkit-autofill {
			box-shadow: 0 0 0 30px $colorPureWhite inset;
			color: $colorGray70;
			line-height: 20px;
			-webkit-text-fill-color: $colorGray70;
			-webkit-text-fill-font-weight: 400;
			-webkit-text-fill-font-size: 16;
		}
		&.invalid {
			background-color: $colorAlertLight;
			border: 2px solid $colorAlert;
		}
		&:disabled{
			background-color: $colorGray10;
			border: none;
		}
	}
	& > div {
		min-height: 24px;
		& > span {
			color: $colorAlert;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
		}
	}
}
