@import "@core/styles/colors.scss";

.backbutton {
  &:focus{
    box-shadow: none;
  }
  &>span{
    color : $colorP1;
    font-size: 14px;
    padding-left: 0.25rem;
    // line-height: 17px;
  }
}