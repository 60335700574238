@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';


.nav-menu {
	min-height: 73px;
	background-color: $colorPureWhite;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);

	@media screen and (max-width: $widthTabletMax) {
		bottom: 0;
		position: fixed;
		width: 100%;
		box-shadow: 0px -4px 24px rgb(0 0 0 / 7%);

		&>nav>.nav-links-loggedin {
			width: 100%;
		}
	}

	& .explore-menu {
		margin-top: 10px !important;
		width: 100%;
	}

	&>div>.nav-links-loggedin {

		&>a,
		&>button {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 5px;
			text-decoration: none;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: $colorGray40;
			text-transform: unset;

			& svg{
				width: 24px;
			}
		}

		&>button:hover {
			background-color: transparent;
		}
	}
}

[data-theme="dark"] .nav-menu {
	background-color: $colorPureWhite;

	&>div>div>a>button>svg{
		stroke: $colorPureWhite ;
	}

	&>div>.nav-links-loggedin {

		&>a,
		&>button {
			color: $colorPureWhite;

			&>svg {
				& path {
					stroke: $colorPureWhite;
				}
			}
		}
	}
}


.active-link {
	color: $colorP1 !important;

	&>svg {
		& path, circle {
			stroke: $colorP1 !important;
		}
	}
}

.active-link-home {
	color: $colorP1 !important;

	&>svg {
		&>path {
			fill: $colorP1;
			stroke: $colorP1;
		}
	}
}

.active-cart-link {
	&>button {
		&>svg {
			stroke: $colorP1;
		}
	}
}

.get-started {
	width: max-content;
	padding: 16px 10px;
	border-radius: 8px;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	text-decoration: none;
}