@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.wrapper {
    .item-wrapper {
        margin-left: 30px;        
        .item-cat {
            color: $colorGray30;
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 32px;
        }

        .item {
            margin-bottom: 30px;

            a {
                text-align: start;
                color: $colorGray70;
                font-size: 16px;

                &:hover {
                    color: $colorP1;
                    text-align: center;
                }
            }
        }
    }

    img{
        // height: 81px;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    p {
        max-width: 191px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: $colorGray50;
        text-align: center;
    }
}