@import '@core/styles/colors.scss';

.sub-page {
	& > h1 {
		color: $colorGray50;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
  & > div {
    & > button {
      width: max-content;
      padding: 0;
    }
  }
}

.active-cart-link {
	& > button {
		& > svg {
			stroke: $colorP1;
		}
	}
}
