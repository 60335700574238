@import '@core/styles/colors.scss';

.brg-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	// height: 62px;
	& > .checkbox {
		position: absolute;
		display: block;
		height: 20px;
		width: 25px;
		z-index: 6;
		opacity: 0;
		cursor: pointer;
	}
	& > .hamburger-lines {
		display: block;
		height: 20px;
		width: 25px;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		& > .line {
			display: block;
			height: 3px;
			width: 100%;
			border-radius: 10px;
			background: $colorGray50;
		}
		& > .line1 {
			transform-origin: 0% 0%;
			transition: transform 0.4s ease-in-out;
			background: $colorGray50;
		}
		& > .line2 {
			transition: transform 0.2s ease-in-out;
			background: $colorGray25;
		}
		& > .line3 {
			transform-origin: 0% 100%;
			transition: transform 0.4s ease-in-out;
			background: $colorGray50;
		}
	}
}
.menu-items {
	position: fixed;
	z-index: 5;
	top: 0;
	left: 0;
	padding-top: 2rem;
	width: 100vw;
	display: flex;
	flex-direction: column;
	text-align: center;
	height: 100vh;
	
	// opacity: 1;
	// transition: height 0ms 400ms, opacity 400ms 0ms;
	& > div {
		overflow-y: auto;
		background-color: $colorPureWhite;
		height: 100%;
		width: 100%;
		padding-left: 10%;
		z-index: 1000;
		margin-top: 1.5rem;
		& > ul:first-child {
			list-style: none;
			padding-top: 10px;
			text-align: left;
			& li {
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 20px;
				padding-bottom: 23px;
				& > a {
					text-decoration: none;
					color: $colorGray70;
				}
			}
		}
		& > .login-register-btn {
			min-width: 200px;
			margin-bottom: 48px;
		}
		& > ul:last-child {
			list-style: none;
			padding-top: 10px;
			text-align: left;
			& > li {
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
				padding-bottom: 16px;
				& > a {
					text-decoration: none;
					color: $colorGray70;
				}
			}
		}
	}
}

// .brg-container input[type='checkbox']:checked ~ .menu-items {
// 	opacity: 1;
// 	transition: opacity 600ms 0ms;
// }
// .brg-container input[type='checkbox']:not(:checked) ~ .menu-items {
// 	opacity: 0;
// 	transition: opacity 600ms 0ms;
// }

.brg-container input[type='checkbox']:checked ~ .hamburger-lines .line1 {
	transform: rotate(45deg);
}

.brg-container input[type='checkbox']:checked ~ .hamburger-lines .line2 {
	transform: scaleY(0);
}

.brg-container input[type='checkbox']:checked ~ .hamburger-lines .line3 {
	transform: rotate(-45deg);
}


.stop-scrolling {
	height: 100%;

	overflow: hidden;
}
