@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.wrapper {
    width: 900px;
    max-width: 100%;

    & .trial-data {

        & svg>path {
            stroke: $colorSuccess40;
        }

        & h4 {
            font-size: 14px;
            font-weight: 700;
            color: $colorGray40;
            margin: 0;
        }
    }

    & .discount-message {
        &>h4 {
            font-size: 14px;
            font-weight: 700;
            color: $colorGray40;
            max-width: 400px;
            margin: 0;
        }
        &>svg>path{
            stroke: $colorP1;
        }
    }

    & .period-cart {
        border-radius: 8px;
        border: 1px solid $colorGray20;
        padding: 16px;
        margin-top: 10px;
        cursor: pointer;



        & .period-name {
            font-size: 14px;
            font-weight: 700;
            color: $colorGray70;
            margin-left: 10px;
        }

        & .save {
            margin-left: 10px;
            font-size: 14px;
            font-weight: 700;
            color: $colorSuccess;
        }

        & .popular {
            font-size: 14px;
            font-weight: 700;
            background-color: $colorWarning10;
            color: $colorWarning50;
            padding: 2px 12px;
            border-radius: 12px;
            // margin-top: -10px;
            // margin-right: -10px;
        }

        & .price {
            font-size: 14px;
            font-weight: 400;
            color: $colorGray40;
            margin: 10px 10px 0;

            & .real-price {
                position: relative;
            }

            & .real-price::before {
                content: '';
                /* Empty content for the pseudo-element */
                position: absolute;
                width: 100%;
                /* Full width of the text */
                height: 1px;
                /* Thickness of the horizontal line */
                background-color: $colorAlert40;
                /* Line color */
                top: 50%;
                /* Position the line in the middle vertically */
                transform: translateY(-50%);
                /* Adjust for perfect centering */
            }

            & .discounted-price {
                color: $colorAlert40;
                margin-left: 10px;
            }
        }
    }

    & .bento-wrapper {
        &>div {
            background: linear-gradient(to right, white 37%, $colorSecondaryLight 37%);
        }
    }

    .discount {
        // box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.10);
        // border-radius: 15px;
        // padding: 32px 28px;
        margin-top: 32px;

        &>h4 {
            font-size: 16px;
            font-weight: 700;
            color: $colorGray60;
            margin-bottom: 20px;
        }

        &>div {
            // min-width: 60%;

            &>div {
                &>div {
                    flex-grow: 1;
                }
            }
        }

        & .discount-msg {
            font-size: 14px;
            margin-bottom: 0;
            margin-top: 8px;
            min-height: 21px;
            color: $colorSuccess;
            max-width: 380px;
        }

        & .discount-error-message {
            margin-top: 8px;
            margin-left: 4px;
            font-size: 12px;
            max-width: 380px;
            color: $colorAlert;
        }
    }
}