@import '@core/styles/colors.scss';

.main-page-title {
	& > div:first-child {
		& > h1 {
			font-weight: 900;
			font-size: 24px;
			line-height: 29px;
			color: $colorP1;
		}
	}
  & > .icons {
    display: flex;
    align-items: center;
  }
}

.active-cart-link{
	& > button {
		& > svg {
			stroke: $colorP1;
		}
	}
}