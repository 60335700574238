@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';



.trial-spacer{
    padding-top: 70px;
    @media only screen and (max-width: $widthMobileMax){
        padding-top: 130px;        
    }
    position: relative;
    z-index: -3;
}

.banner-wrapper {
    padding: 12px;
    background: $colorWarning10;
    position: absolute;
    width: 100%;
    top: 72px;
    left: 0;
    z-index: -1;

    & h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        // text-align: center;
        margin: 0;
        @media only screen and (max-width: $widthTabletMax){
            font-size: 14px;            
        }
        @media only screen and (max-width: $widthMobileMax){
            margin-bottom: 20px;        
        }
    }

    & a{
        padding: 16px 42px;
    }

    & button{
        max-width: 200px;
        height: 48px;
    }

}

.premium-banner{
    z-index: 1;
    display: none;
}

.spacer{
    display: none;
    position: relative;
    padding-top: 80px;
    @media only screen and (max-width: $widthMobileMax){
        padding-top: 130px;        
    }
    z-index: -3;
}

.payment-modal{
    @media only screen and (min-width: '1300px'){
         width: 1240px;
    }
 }