@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';

.search-suggest-wrapper {
	width: 270px;
	height: 42px;
	background-color: $colorGray10;
	border-radius: 8px;
	border: 1px solid $colorGray15;
	padding: 12px;
	gap: 12px;
	position: relative;
	@media screen and (max-width: 1400px) {
		width: 150px;
		margin-left: 10px;
	}
	@media screen and (max-width: $widthTabletMax) {
		width: 100%;
	}
	& > button {
		border: none;
		background-color: unset;
	}
	& > input {
		// padding-left: 14.25px;
		font-size: 12px;
		font-weight: 400;
		line-height: 15px;
		background-color: transparent;
		width: 100%;
		outline: none;
		border: none;
	}
	& > .search-suggest-dropdown {
		position: absolute;
		top: 42px;
		width: 312px;
		background-color: $colorPureWhite;
		margin-left: -12px;
		border: 1px solid rgba(13, 13, 13, 0.1);
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
		border-radius: 8px;
		padding: 0 12px;
	}
	& > .search-suggest-result {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		max-width: 100vw;
		min-height: 100vh;
		background-color: $colorPureWhite;
	}
}

[data-theme="dark"] .search-suggest-wrapper {
	background-color: $backgroundDark;
	border: 1px solid $colorGray15;
	& > button > svg > path{
		stroke: $colorPureWhite;
	}
	& > input {
		color: $colorPureWhite;
		&::placeholder{
			color: $colorPureWhite;
		}
	}
	& > .search-suggest-dropdown {
		background-color: $backgroundDark;
	}
	& > .search-suggest-result {
		background-color: $backgroundDark;
	}
}