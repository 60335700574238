@import '@core/styles/_colors.scss';

.billing-address-info {
	width: 90%;
	list-style: none;
	font-size: 14px;
	color: $colorGray30;
	padding: 0;
	margin: 0;

	&>li {
		padding: 1rem 0;
		border-bottom: 1px solid $colorGray15;

		&:last-child {
			border-bottom: none;
		}

		&>p {
			margin: 0;
			padding: 0;
			min-width: 105px;
			align-self: baseline;
		}

		&>span {
			font-size: 16px;
			color: $colorGray70;
			padding-left: 1rem;
			word-break: break-all;
		}
	}
}