@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.wrapper {
    // min-width: 1000px;
    min-height: 530px;
    @media only screen and (max-width: $widthTabletMax){
        min-height: unset;
    }

    &>div>h3 {
        margin-bottom: 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        color: $colorGray70;
    }
}