@import '@core/styles/devicesize.scss';

.layout-container {
	display: flex;
	flex-flow: nowrap column;
	min-height: 100vh;

	& > .loading{
		display: flex;
		justify-content: center;
		align-items: center;
		height: calc(100vh - 400px);
	}
}

.content-wrapper {
	flex-grow: 1;
	@media screen and (min-width: $widthDesktopMin) {
		width: 100%;
		flex-grow: 1;
	}
}


