

* {
  font-family: 'Montserrat';
}

[data-theme="dark"] {
  /* background-color: red ; */
}

.hover-shrink{
	transition: all 0.1s ease-in-out;	
}
.hover-shrink:hover{
  transform: scale(0.95);
}

.col-12 {
  flex-grow: 1;
  transition: all 400ms ease;
}

.col-8 {
  flex-grow: 1;
  transition: all 400ms ease;
}

.col-4 {
  flex-grow: 1;
  transition: all 400ms ease;
}

.col-0 {
  width: 0 !important; 
  overflow: hidden;
  height: 0;
  opacity: 0;
  flex-shrink: 1;
  transition: all 400ms ease;
}

a, a:hover, a:active, a:visited, a:focus {
  text-decoration:none !important;
}