
@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.bento {
    display: grid;
    grid-template-areas:
        'box1 box1 box1 box1 box4 box4'
        'box2 box2 box3 box3 box4 box4'
        'box5 box5 box6 box6 box6 box6'
        'box5 box5 box7 box7 box7 box7';
    gap: 7px;

    &>div {
        background-color: $colorPureWhite;
        border-radius: 8px;
        box-shadow: 0px 4px 24px 0px rgba(62, 9, 44, 0.15);
        padding: 14px;
        transition: all 0.1s ease-in-out;
        
        &:hover{
          transform: scale(1.15);
        }

        &>h5{
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin: 0;
            color: $colorGray40;
        }               
    }

    & .box1 {
        grid-area: box1;
        min-height: 73px;
        background-image: url('../../../../../../../assets/images/pages/plans/e-learning-courses.png');
        background-repeat: no-repeat;
        background-position: center right;
    }
    
    & .box2 {
        grid-area: box2;
        min-height: 108px;
        background-image: url('../../../../../../../assets/images/pages/plans/standard-certificate.png');
        background-repeat: no-repeat;
        background-position: bottom left;
    }
    
    & .box3 {
        grid-area: box3;
        min-height: 108px;
        background-image: url('../../../../../../../assets/images/pages/plans/free-consultation-meetings.png');
        background-repeat: no-repeat;
        background-position: bottom center;
    }
    
    & .box4 {
        grid-area: box4;
        background-image: url('../../../../../../../assets/images/pages/plans/career-path-guid.png');
        background-repeat: no-repeat;
        background-position: bottom right;
    }
    
    & .box5 {
        grid-area: box5;
        min-height: 217px;
        background-image: url('../../../../../../../assets/images/pages/plans/skill-tree.png');
        background-repeat: no-repeat;
        background-position: bottom left;
    }
    
    & .box6 {
        grid-area: box6;
        background-image: url('../../../../../../../assets/images/pages/plans/public-profile.png');
        background-repeat: no-repeat;
        background-position: bottom right;
    }
    
    & .box7 {
        grid-area: box7;
        background-image: url('../../../../../../../assets/images/pages/plans/cover-letter.png');
        background-repeat: no-repeat;
        background-position: bottom right;
    }
}

