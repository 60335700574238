@import '@core/styles/colors.scss';

.sub-page {
	& > h1 {
		color: $colorGray50;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.active-cart-link {
	& > button {
		& > svg {
			stroke: $colorP1;
		}
	}
}

.button-wrapper{
	display: flex;
	gap: 5px;
}
