@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.popover-container {
	& > .popover-content {
		width: 360px;
		overflow: hidden;
		padding: 24px 0 24px 24px;
		position: absolute;
		top: 100%;
		right: 0;
		z-index: 20000;
		background: $colorPureWhite;
		border-radius: 24px;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);

		&.mobile-fullscreen {
			@media screen and (max-width: $widthMobileMax) {
				position: fixed;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100vw;
				min-height: 100vh;
				border-radius: 0;
				z-index: 9999;

				& > .popover-main {
					max-height: 85vh;
				}
			}
		}

		& > .popover-main {
			padding-right: 24px;
			max-height: 450px;
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: 4px;
				height: 4px;
			}
			&::-webkit-scrollbar-track {
				background: $colorGray20;
			}
			&::-webkit-scrollbar-thumb {
				background: $colorGray40;
			}
			&::-webkit-scrollbar-thumb:hover {
				background: $colorGray50;
			}
		}

		& > nav {
			display: flex;
			margin-bottom: 32px;
			padding-right: 24px;

			& > h1 {
				font-weight: 700;
				font-size: 18px;
				color: $colorGray50;
				margin: 0 auto;
			}
		}
	}
}
