@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.wrapper {
    background-color: $backgroundDark;
    background: linear-gradient(90deg, #BB3A90 0%, #1D2233 19.52%, #1D2233 47.28%, #1D2233 75.35%, #BB3A90 100%);
    color: $colorPureWhite;
    // position: fixed;
    width: 100%;
    z-index: 4;
    font-size: 14px;
    font-weight: 700;
    padding: 10px;

    // @media only screen and (max-width: $widthTabletLargeMax){
    //     position: relative;
    // }

    & p{
        margin: 0;
    }

    & span{
        display: inline-block;
        min-width: 125px;
        padding-left: 5px;
        text-align: left;
    }

    & a{
        padding: 4px 16px;
        font-size: 14px;
    }
}