@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';

.country-select {
	position: relative;
	margin: 0;
	& > label {
		color: $colorGray70;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		padding: 0;
	}
	&__input {
		border-radius: 8px;
		border: 1px solid $colorGray20;
		background-color: transparent;
		outline: none;
		padding: 6px 12px;
		& > img {
			width: 25px;
		}
		& > input {
			width: 100%;
			border: none;
			outline: none;
			background-color: transparent;
			&::placeholder{
				color: $colorGray30;
			}
		}
		&__invalid {
			background-color: $colorAlertLight;
			border: 2px solid $colorAlert;
		}
	}
}

.countries-wrapper {
	position: absolute;
	// width: 100%;
	// margin-top: 40px;
	border: 1px solid $colorGray20;
	border-radius: 8px;
	background-color: $colorPureWhite;
	z-index: 1;
	top: 72px;
	&__horizontal{
		right: 0;
		top: 40px;
	}
	@media screen and (max-width: $widthTabletMax) {
		top: 70px;
	}
	&__list {
		max-height: 200px;
		margin: 10px;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		&::-webkit-scrollbar {
			// display: none;
			width: 3px;
			height: 3px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: $colorGray20;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: $colorGray40;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: $colorGray50;
		}
	}

	.list-item {
		padding: 5px 0;
		& > img {
			width: 25px;
		}
		& > p {
			margin: 0;
		}
	}
}

.country-select-modal {
	// padding: 24px;
	// min-height: 90vh;
	& > input {
		width: 100%;
		border-radius: 8px;
		border: 1px solid $colorGray20;
		background-color: transparent;
		outline: none;
		padding: 10px 12px;
	}
	& > .countries-wrapper-mobile {
		width: 100%;
		margin-top: 5px;
		& > .countries-wrapper-mobile-list {
			height: 72vh;
			// margin: 10px;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			&::-webkit-scrollbar {
				// display: none;
				width: 3px;
				height: 3px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: $colorGray20;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: $colorGray40;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: $colorGray50;
			}
		}

		.list-item {
			padding: 5px 0;
			& > img {
				width: 25px;
			}
			& > p {
				margin: 0;
			}
		}
	}
}
