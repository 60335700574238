@import '@core/styles/colors.scss';
@import '@core/styles/_devicesize.scss';


.content-wrapper {
	flex-grow: 1;
	@media screen and (min-width: $widthDesktopMin) {
		width: 100%;
		flex-grow: 1;
	}
}
.company-dashboard-header {
	background-color: $colorSecondaryLight;

	& > div {
		& > .company-dashboard-header_company-name {
			& > img {
				width: 30px;
			}
			& > h5 {
				font-weight: 700;
				font-size: 16px;
				margin: 0;
			}
		}
		& > .company-dashboard-header_company-role {
			font-weight: 700;
			font-size: 16px;
			@media screen and (max-width: $widthMobileMax) {
				max-width: 30%;
				font-size: 12px;
			}
		}
	}
}