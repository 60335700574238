@import '@core/styles/_colors.scss';

.empty-list-container {
  & > svg {
    max-width: 100%;
  }

	& > h1 {
		font-weight: 700;
		font-size: 14px;
		color: $colorGray40;
		text-align: center;
    margin-top: 20px;
	}

	& > p {
		font-weight: 400;
		font-size: 14px;
		color: $colorGray40;
		text-align: center;
	}
}
