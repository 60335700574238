@import '@core/styles/colors.scss';

.link-button {
	position: relative;
	color: $colorP1;
	background-color: $colorPLight;
	text-decoration: none;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	padding: 1% 1%;
	border-radius: 8px;
	text-align: center;
	z-index: 3;
	transition: all 0.1s ease-in-out;
	&:hover{
		transform: scale(0.95);
	}
	&::before {
		z-index: 0;
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background-color: white;
		opacity: 0;
		border-radius: 8px;
		transition: opacity 0.1s ease-in-out;
	}
	&:hover {
		&::before {
			opacity: 0.15;
		}
	}
	&.primary-light {
		&:hover {
			color: $colorP1;
			&::before {
				opacity: 0.15;
			}
		}
	}
	&.primary-color {
		color: $colorPureWhite;
		background-color: $colorP1;
		border-radius: 12px;
	}
	&.simple {
		color: $colorP1;
		background-color: transparent;
	}
	&.secondary {
		color: $colorPureWhite;
		background-color: $colorAccent;
	}
	&.secondary-light {
		color: $colorAccent;
		background-color: $colorSecondaryLight;
	}
	&.secondary-simple {
		color: $colorAccent;
		background-color: transparent;
	}
}
