@import '@core/styles/devicesize.scss';
@import '@core/styles/colors.scss';

.normal {
	position: fixed;
	z-index: 1001;
	border-radius: 24px;
	display: flex;
	flex-direction: column;
	& > div {
		overflow: auto;
		max-width: 90vw;
		width: max-content;
		max-height: 85vh;
		background: $colorPureWhite;
		border-radius: 24px;
		display: flex;
		flex-direction: column;
	}
}

.confirmation {
	position: fixed;
	z-index: 1001;
	border-radius: 24px;
	display: flex;
	flex-direction: column;
	& > div {
		overflow: auto;
		max-width: 90vw;
		width: max-content;
		max-height: 85vh;
		background: $colorPureWhite;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
	}
}

.mobile-fullscreen {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 1001;
	@media screen and (max-width: $widthMobileMax) {
		position: fixed;
		width: 100%;
	}
	& > div {
		overflow: auto;
		max-height: 85vh;
		background: $colorPureWhite;
		z-index: 1001;
		border-radius: 24px;
		display: flex;
		flex-direction: column;
		@media screen and (max-width: $widthMobileMax) {
			width: 100%;
			height: 100vh;
			border-radius: 0;
			max-height: 100vh;
		}
	}
}



.mobile-slide-up {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	overflow: auto;
	z-index: 1001;
	& > div {
		overflow: auto;
		display: flex;
		flex-direction: column;
		border-radius: 24px;
		max-height: 85vh;
		background: $colorPureWhite;
		@media screen and (max-width: $widthMobileMax) {
			max-height: 90vh;
			width: 100%;
			border-radius: 30px 30px 0 0;
		}
	}
	@media screen and (max-width: $widthMobileMax) {
		position: fixed;
		bottom: 0;
		width: 100%;
	}
}

.modal-content {
	width: 100%;
	padding: 24px;
	padding-bottom: 0;
	margin-bottom: 24px;
	height: 100%;
	overflow-y: auto;
	flex-grow: 1;
	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background: $colorGray20;
	}

	&::-webkit-scrollbar-thumb {
		background: $colorGray30;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: $colorGray40;
	}
}

.modal-header {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 24px 24px 0px 16px;
	// position: sticky;
	top: 0;

	& > h1 {
		font-weight: 700;
		font-size: 18px;
		color: $colorGray50;
		margin: 0;
	}
}
