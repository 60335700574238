@import '@core/styles/colors.scss';

.brg-container {
    z-index: 1299;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// height: 62px;
	& > .checkbox {
		position: absolute;
		display: block;
		height: 32px;
		width: 32px;
		z-index: 5;
		opacity: 0;
		cursor: pointer;
	}
	& > .hamburger-lines {
		padding: 0 6px;
		display: block;
		height: 16px;
		width: 32px;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		& > .line {
			display: block;
			height: 2px;
			width: 100%;
			border-radius: 10px;
			background: $colorGray50;
		}
		& > .line1 {
			transform-origin: 0% 0%;
			transition: transform 0.4s ease-in-out;
			background: $colorGray50;
		}
		& > .line2 {
			transition: transform 0.2s ease-in-out;
			background: $colorGray25;
		}
		& > .line3 {
			transform-origin: 0% 100%;
			transition: transform 0.4s ease-in-out;
			background: $colorGray50;
		}
	}
}
.slider-wrapper{
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4;
	background: rgba(13, 13, 13, 0.50);
}
.menu-items {
    // background: rgba(13, 13, 13, 0.50);
	position: fixed;
	z-index: 4;
	top: 0;
	right: 0;
	// padding-top: 2rem;
	width: 100vw;
	display: flex;
	// flex-direction: column;
    justify-content: flex-end;
	text-align: center;
	height: 110vh;
    overflow-y: auto;
	// opacity: 1;
	// transition: height 0ms 400ms, opacity 400ms 0ms;
	& > div {
		background-color: $colorPureWhite;
		height: 100%;
		// padding-left: 10%;
        // max-width: 500px;
		z-index: 1000;
		// margin-top: 1.5rem;	
	}
}

// .brg-container input[type='checkbox']:checked ~ .menu-items {
// 	opacity: 1;
// 	transition: opacity 600ms 0ms;
// }
// .brg-container input[type='checkbox']:not(:checked) ~ .menu-items {
// 	opacity: 0;
// 	transition: opacity 600ms 0ms;
// }

.brg-container input[type='checkbox']:checked ~ .hamburger-lines .line1 {
	transform: rotate(45deg);
}

.brg-container input[type='checkbox']:checked ~ .hamburger-lines .line2 {
	transform: scaleY(0);
}

.brg-container input[type='checkbox']:checked ~ .hamburger-lines .line3 {
	transform: rotate(-45deg);
}


.stop-scrolling {
	height: 100%;
	overflow: hidden;
}
