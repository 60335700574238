@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.wrapper {
    padding-top: 10px;

    & img {
        z-index: 5;
        background-color: white;
    }

    & h3 {
        font-size: 14px;
        font-weight: 700;
        color: $colorGray40;

        &>span {
            color: $colorSecondaryLecture;
            font-weight: 400;
        }
    }

    & p {
        font-size: 12px !important;
        font-weight: 400;
        color: $colorGray40;
    }

    &>div {
        position: relative;
        padding-bottom: 35px;
    }

    &>div::before {
        content: '';
        border: 1px solid $colorSecondaryLecture;
        position: absolute;
        height: 100%;
        left: 7px;
        z-index: 0;
    }

    &>div:last-child:before {
        border: none;
    }

    &>div:last-child {
        padding: 0;
    }
}