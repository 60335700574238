@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';


.wrapper {
    text-align: center;
    padding: 24px;
    width: 443px;
    max-width: 100%;
    background-image: url('../../assets/images/go-permium-background.png');
    background-size: 100% 100%;

    & .modal-header {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 0;

        & button {
            padding: 0;
        }
    }

    & .modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        margin-top: 30px;

        &>h3 {
            color: $colorWarning40;
            font-size: 24px;
            font-weight: 900;
        }

        &>p {
            max-width: 300px;
            font-size: 16px;
            font-weight: 700;
            color: $colorGray50;
        }

        &>a{
            padding: 16px 40px;
            margin-bottom: 30px;
        }
    }

}