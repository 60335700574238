@import '@core/styles/colors.scss';

.notificaton-container {
  position: relative;
}
.notification-btn {
	&:focus {
		box-shadow: none;
	}
}

[data-theme="dark"] .notificaton-container > button > svg > path{
	stroke: $colorPureWhite;
}
