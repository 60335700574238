@import '@core/styles/colors.scss';

.action-button {
	height: 54px;
	width: 100%;
	background-color: $colorP1;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	color: $colorPureWhite;
	border: none;
	border-radius: 12px;
	box-shadow: 0px 12px 24px rgba(211, 86, 146, 0.2);
	outline: none;
	
	&:disabled {
		background-color: $colorGray20;
		// box-shadow: 0px 12px 24px rgba(197, 179, 187, 0.2);
	}
	&.secondary {
		color: $colorP1;
		background-color: $colorPLight;
		box-shadow: none;
		// width: unset;
	}
	&.blank {
		height: unset;
		width: unset;
		background-color: transparent;
		color: $colorP1;
		font-weight: unset;
		font-size: unset;
		box-shadow: none;
		&:disabled {
			color: $colorGray20;
		}
	}
}

.shrink{
	transition: all 0.1s ease-in-out;
	&:hover{
		transform: scale(0.95);
	}
}