// @import './GuestUser.module.scss';
@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.loggedin-profile-container {
	flex-wrap: wrap;
	margin: auto;
	.vip-section {
		border: solid 2px #faa61a;
		padding: 22px 12px;
		border-radius: 12px;
		display: flex;
		gap: 18px;
		flex-flow: column wrap;
		align-items: center;
		background: #f7f6f3;

		& > h3 {
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
		}

		& > p {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			text-align: center;
		}
	}

	.user-box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		& > .info {
			padding: 20px;

			& > .empty-name {
				font-weight: 400;
				font-size: 16px;
				color: $colorGray30;
				margin-bottom: 10px;
				position: relative;

				&::after {
					content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.6825 14.9405L11.5725 10.0505C12.15 9.47305 12.15 8.52805 11.5725 7.95055L6.6825 3.06055' stroke='%23A6A5A3' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					position: absolute;
					right: -10%;
					top: 15%;
				}
			}

			& > .user-email {
				font-weight: 400;
				font-size: 12px;
				color: $colorGray30;

				& > svg {
					margin: 0 0 0 5px;
				}
			}
		}
	}

	& > .email-not-verified {
		width: 100%;
		height: 50px;
		background-color: #fff5e6;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $colorWarning50;
		padding: 0 17.5px;

		& > .msg-container {
			display: flex;
			align-items: center;

			& > .text {
				margin-inline-start: 13px;
				font-weight: 400;
				font-size: 14px;
			}
		}

		& > .action-container {
			display: flex;
			align-items: center;
			justify-content: center;

			& > a {
				text-decoration: none;
				background-color: transparent;
				color: $colorWarning50;
				font-weight: 400;
				font-size: 14px;
				margin-right: 13px;
			}
			& > .icon {
				content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.68262 14.9405L11.5726 10.0505C12.1501 9.47305 12.1501 8.52805 11.5726 7.95055L6.68262 3.06055' stroke='%23AD6C00' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			}
		}
	}
}

.course-certificate-verify-name {
	border-radius: 16px;
	// padding: 24px 32px;
	& > h1 {
		font-weight: 900;
		font-size: 24px;
		line-height: 29px;
		color: $colorGray70;
	}
	& > p {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: $colorGray40;
	}
	& > form {
		margin-bottom: 24px;
		& > button {
			width: 100%;
			padding: 16px;
			background-color: $colorP1;
			color: $colorPureWhite;
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			box-shadow: 0px 12px 24px rgba(211, 86, 146, 0.2);
			border-radius: 12px;
			border: none;
			cursor: pointer;
			&:disabled {
				background-color: $colorGray20;
				box-shadow: none;
			}
		}
	}
}

.verify-name-checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 26px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 14px;
	line-height: 17px;
	color: $colorGray70;
	& > input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	& > span {
		position: absolute;
		top: 0;
		left: 0;
		height: 24px;
		width: 24px;
		background-color: $colorPureWhite;
		border-radius: 8px;
		border: 1px solid $colorGray30;
		&:hover {
			background-color: #ccc;
		}
		&::after {
			content: '';
			position: absolute;
			display: none;
			left: 9px;
			top: 4px;
			width: 6px;
			height: 12px;
			border: solid white;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	& > input:checked + span {
		border: unset;
		background-color: $colorP1;
		color: $colorP1;
		&::after {
			display: block;
		}
	}
}

.social-media-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $colorGray10;
	border-radius: 16px;
	padding: 12px 24px;
	// margin-bottom: 48px;
	& > .icons {
		display: flex;
		align-items: center;
		& > a {
			width: 100%;
			& > svg {
				width: 40px;
				height: 40px;
			}
		}
	}
}

@media only screen and (max-width: $widthMobileMax) {
	.social-media-container {
		padding: 10px;

		& > .icons {
			flex-grow: 0;
		}
	}
}
.profile-menus {
	grid-template-columns: 1fr 1fr 1fr;
	display: flex;
	justify-content: space-around;
	flex-flow: row wrap;
	align-items: center;

	& > .item-icon {
		// width: 25%;
		margin-bottom: 50px;

		& > a {
			display: flex;
			flex-flow: nowrap column;
			align-items: center;
			justify-content: space-between;
			text-decoration: none;
			color: transparent;
			& > img {
				width: 38px;
				height: 38px;
			}

			& > .item-icon-label {
				margin-top: 12px;
				color: $colorGray60;
				font-weight: 400;
				font-size: 14px;
			}
		}
	}
	@media only screen and (max-width: $widthTabletMax) {
		flex-flow: nowrap column;

		& > .item-icon {
			width: 100%;
			margin: 0;
			&:last-child > a {
				border-bottom: none;
			}

			& > a {
				flex-flow: nowrap row;
				justify-content: flex-start;
				width: 100%;
				height: 72px;
				border-bottom: solid 1px rgba(13, 13, 13, 0.1);
				position: relative;
				margin-bottom: 0;

				& > .item-icon-label {
					margin: 0 0 0 14px;
				}
				&::after {
					content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.6825 14.9405L11.5725 10.0505C12.15 9.47305 12.15 8.52805 11.5725 7.95055L6.6825 3.06055' stroke='%23A6A5A3' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					position: absolute;
					right: 0;
					top: 40%;
				}
			}
		}
		& > .item-icon-logout {
			width: 100%;
			margin: 0;
			&:last-child > div {
				border-bottom: none;
			}

			& > div {
				flex-flow: nowrap row;
				justify-content: flex-start;
				display: flex;
				align-items: center;
				width: 100%;
				height: 72px;
				border-bottom: solid 1px rgba(13, 13, 13, 0.1);
				position: relative;
				margin-bottom: 0;
				& > img {
					width: 38px;
					height: 38px;
				}
				& > .item-icon-label {
					margin: 0 0 0 14px;
					color: $colorP1;
					font-weight: 400;
					font-size: 14px;
				}
				&::after {
					content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.6825 14.9405L11.5725 10.0505C12.15 9.47305 12.15 8.52805 11.5725 7.95055L6.6825 3.06055' stroke='%23A6A5A3' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					position: absolute;
					right: 0;
					top: 40%;
				}
			}
		}
	}
}

.card-title-color {
	color: $colorP1;
}

.desktop-bot-menu {
	padding: 24px 0;
	border-top: 1px solid $colorGray20;
	margin-top: -24px;
	& > div,
	a {
		& > img {
			width: 15px;
			height: 15px;
		}
		&.item-icon-logout {
			& > span {
				color: $colorP1;
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
				text-align: center;
			}
		}
		&.item-icon {
			& > a {
				color: $colorGray70;
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
				text-align: center;
				text-decoration: none;
			}
		}
	}
}
