@import '@core/styles/_colors.scss';
@import "@core/styles/_devicesize.scss";

.avatar-container {
	width: 110px;
	height: 110px;
	border-radius: 16px;
	background-color: $colorGray10;
	position: relative;
	overflow: hidden;

	&:hover {
		&>.add-image {
			opacity: 1;
			background-color: $colorGray30;
		}
	}

	&>.user-avatar {
		display: flex;
		align-items: center;
		justify-content: center;
		// height: 80px;

		&>img {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}

		&>.default-avatar {
			margin-top: 15px;
		}
	}

	&>.add-image {
		background-color: #A6A5A3;
		opacity: 0.9;
		position: absolute;
		z-index: 3;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $colorPureWhite;
		width: 100%;
		height: 25%;

		&>label {
			color: $colorPureWhite;
			font-weight: 400;
			font-size: 12px;
			cursor: pointer;
			display: block;
			width: 100%;
			height: 100%;
			justify-content: center;
			display: flex;
			align-items: center;
		}

		&>.file-input {
			display: none;
		}
	}
}

.avatar-container-small {
	width: 88px;
	height: 88px;

	&>.add-image {
		height: 35%;
	}

	&>.user-avatar {
		&>.default-avatar {
			margin-top: 0;
		}
	}

}

.cropper-modal-container {

	width: 500px;
	height: 500px;


	&>.cropper-container {
		width: 100%;
		height: 78%;
		position: relative;
	}
}

@media only screen and (max-width: $widthMobileMax) {
	.cropper-modal-container {
		width: 100%;
		height: 80%;
		min-width: 270px;
	}
}