@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';


.user-menu-button{
	border: none;
	background-color: unset;
}
.user-menu{
	& a{
		color: $colorGray40 !important;
		text-decoration: none;
	}
	& > div{
		border-radius: 12px;
	}	
	& svg{
		margin-right: 12px;
	}
	& ul{
		padding: 18px 18px;
	}
	& li{
		padding: 0;
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: 0;
			padding: 4px 4px 4px 0;
		}

		& a{
			padding: 4px 4px 4px 0;
		}
	}	
	& .logout-link{
		color: $colorAlert;
	}	
}

[data-theme="dark"] .user-menu-button > svg > path{
	stroke: $colorPureWhite;
}