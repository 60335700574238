@import "@core/styles/_colors.scss";
@import "@core/styles/_devicesize.scss";

.logout-modal {
  max-width: 344px;
	& > h1 {
		font-weight: 700;
		font-size: 16px;
		color: $colorGray70;
	}

	& > p {
		font-weight: 400;
		font-size: 14px;
		color: $colorGray50;
	}

  & > .modal-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > button {
      background: transparent;
      border: none;
    }
    & > .logout-button {
      color: $colorAlert;
      margin-left: 30px;
    }
  }
}

@media only screen and (max-width: $widthMobileMax) {
  .logout-modal {
    min-width: 270px;
  }
}