@import '@core/styles/colors.scss';

.badge-wrapper {
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	width: fit-content;
	height: 16px;
	padding: 0px 8px;
	&.completed {
		background-color: $colorSuccess10;
		color: $colorSuccess;
		border-radius: 12px;
	}
	&.progressing {
		background-color: $colorWarning10;
		color: $colorWarning50;
		border-radius: 12px;
	}
	&.updated {
		background-color: $colorSuccess;
		color: $colorSuccess10;
		border-radius: 12px;
	}
	&.coming_soon {
		background-color: $colorWarning40;
		color: $colorPureWhite;
		border-radius: 12px;
	}
}
