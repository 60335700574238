@import '@core/styles/colors.scss';

.dropdown-wrapper {
	padding: 12px 0;
	border-bottom: 0.5px solid #0d0d0d1a;
	&:last-child {
		border-bottom: none;
	}
	& > img {
		width: 86px;
		height: 81px;
		margin-right: 12px;
		border-radius: 6px;
	}
	& > .item {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 4px;
		& > .badge {
			max-width: 86px;
			height: 16px;
			padding: 0px 8px 0px 4px;
			background: $colorSuccess10;
			color: $colorSuccess;
			border-radius: 12px;
			font-weight: 700;
			font-size: 10px;
			line-height: 12px;
		}
		& > h4 {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			color: $colorGray70;
			margin-bottom: 8px;
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
			& > svg {
				flex: 0.2;
				flex-shrink: 0;
			}
			& > a {
				flex: 0.8;
				text-decoration: none;
				color: $colorGray70;
			}
		}
		& > p {
			color: $colorGray40;
			margin-bottom: 9px;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			& > span {
				&::after {
					margin: 0 8px;
					content: '\A';
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background: $colorGray30;
					display: flex;
				}
			}
		}
		& > span {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 4px;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: $colorGray70;
		}
	}
}

.empty-search-svg {
	width: 285px;
	height: 281px;
	& > svg {
		width: 226px;
		height: 110px;
		margin-bottom: 24px;
	}
	& > h4 {
		margin-bottom: 4px;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		color: $colorGray40;
	}
	& > p {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: $colorGray40;
	}
}
