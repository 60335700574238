@import '@core/styles/_colors.scss';

.footer-under-container {
	width: 100%;
	// height: 342px;
	background-color: $colorGray10;

	&>.footer-container {
		flex-grow: 0;
		display: grid;
		align-items: flex-start;
		justify-items: center;
		grid-template-columns: 30% 23% 23% 23%;
		gap: 10px;

		&>.logo-container {
			display: grid;

			&>.logo {
				max-width: 60%;
			}

			&>.title {
				font-weight: 400;
				font-size: 16px;
				color: $colorGray40;
				margin-top: 20px;
			}

			&>.copyright {
				font-weight: 700;
				font-size: 10px;
				color: $colorGray40;
				margin-top: 8px;
			}

			&>.imprint {
				margin: 0;
				width: max-content;
				font-weight: 700;
				font-size: 12px;
				color: $colorGray40;
				text-decoration: none;
			}

			&>.info {
				font-weight: 400;
				font-size: 16px;
				color: $colorGray40;
				margin-top: 20px;
			}
		}

		&>.links-container {
			&>ul {
				margin: 0;
				padding: 0;
				// display: flex;
				// flex-direction: column;
				// gap: 24px;

				&>li {
					list-style: none;
					margin-bottom: 14px;
					font-weight: 400;
					font-size: 16px;

					&>a {
						text-decoration: none;
						color: inherit;
					}

					&>svg {
						margin-right: 16px;
					}
				}
			}

			&>.links-header {
				font-weight: 400;
				font-size: 20px;
				color: $colorP1;
				margin-bottom: 16px;
			}
		}
	}
}

[data-theme="dark"] .footer-under-container {
	background-color: $backgroundFullDark;

	&>.footer-container {

		&>.logo-container {

			&>.title {
				color: $colorPureWhite;
			}

			&>.copyright {
				color: $colorPureWhite;
			}

			&>.imprint {
				color: $colorPureWhite;
			}

			&>.info {
				color: $colorPureWhite;
			}
		}

		&>.links-container {
			&>ul {
				&>li {
					&>a {
						color: $colorPureWhite;
					}
				}
			}

			&>.stay-touch {

				& svg g,
				svg g path {
					fill: $colorPureWhite;
				}
			}
		}
	}
}