@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.wrapper {
    width: 900px;
    max-width: 100%;

    & h3 {
        font-size: 16px;
        font-weight: 700;
        color: $colorP1;
        margin-bottom: 30px;
    }

    & h4 {
        font-size: 12px;
        color: $colorGray40;
        margin-bottom: 18px;

        &>span {
            font-size: 14px;
            font-weight: 700;
        }
    }

    & p {
        font-size: 14px;
        color: $colorGray40;
        margin: 30px 0 18px;

        &>span {
            font-size: 12px;
            font-weight: 700;
            color: $colorP1;
        }
    }

    & .bento-wrapper {
        &>div {
            background: linear-gradient(to right, white 37%, $colorSecondaryLight 37%);
        }       
    }
}