@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.consent-container {
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 999;
	padding: 24px;
	background-color: $colorGray60;
	display: flex;
	align-items: center;
	justify-content: center;

	&>.close-btn {
		position: absolute;
		right: 20px;
	}

	&>.consent-info {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6px;

		&>p {
			font-size: 14px;
			font-weight: 400;
			color: $colorPureWhite;
			text-align: center;
			margin: 0;
		}

		&>.actions {
			display: flex;
			align-items: center;
			gap: 12px;

			&> :is(.ok-btn, .privacy-policy-btn) {
				width: auto;
				height: 30px;
				padding: 6px;
				font-size: 13px;
				font-weight: 500;
				border-radius: 6px;
			}
		}
	}
}

@media only screen and (max-width: $widthTabletMax) {
	.consent-container {
		bottom: 66px;

		&>.consent-info {
			flex-direction: column;
			gap: 10px;
		}
	}
}