@import '@core/styles/colors.scss';

.fallback-spinner {
  position: absolute;
  background-color: $colorPureWhite;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}