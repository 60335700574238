@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.wrapper {
	border-radius: 15px;
	box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.07);
	padding: 24px 26px;
	width: 100%;
	flex-wrap: wrap;
	margin: 0 auto;
	background-color: $colorPureWhite;
	height: fit-content;
	position: sticky;
	top: 98px;

	&.mobile {
		border-radius: 0;
		padding-top: 50px;
		box-shadow: none;
		padding: 24px 26px 12px;
		// background-color: red;

		& .dashboard-menu {
			height: calc(100vh - 220px);
			margin-top: 20px;
			overflow-y: auto;
		}
	}

	.vip-section {
		border: solid 2px #faa61a;
		padding: 22px 12px;
		border-radius: 12px;
		display: flex;
		gap: 18px;
		flex-flow: column wrap;
		align-items: center;
		background: #f7f6f3;

		&>h3 {
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
		}

		&>p {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			text-align: center;
		}
	}

	.user-box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;


		&>.info {
			padding: 20px;

			&>.empty-name {
				font-weight: 400;
				font-size: 16px;
				color: $colorGray30;
				margin-bottom: 10px;
				position: relative;

				&::after {
					content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.6825 14.9405L11.5725 10.0505C12.15 9.47305 12.15 8.52805 11.5725 7.95055L6.6825 3.06055' stroke='%23A6A5A3' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					position: absolute;
					right: -10%;
					top: 15%;
				}
			}

			&>.user-email {
				font-weight: 400;
				font-size: 12px;
				color: $colorGray30;
				word-break: break-all;

				&>svg {
					margin: 0 0 0 5px;
				}
			}
		}
	}

	&>.email-not-verified {
		width: 100%;
		height: 50px;
		background-color: #fff5e6;
		border-radius: 12px;
		display: flex;
		gap: 7px;
		justify-content: space-between;
		align-items: center;
		color: $colorWarning50;
		padding: 0 17.5px;
		margin-top: 4px;

		&>.msg-container {
			display: flex;
			align-items: center;

			&>.text {
				margin-inline-start: 13px;
				font-weight: 400;
				font-size: 14px;
			}
		}

		&>.action-container {
			display: flex;
			align-items: center;
			justify-content: center;

			&>a {
				text-decoration: none;
				background-color: transparent;
				color: $colorWarning50;
				font-weight: 400;
				font-size: 14px;
				margin-right: 6px;
			}

			&>.icon {
				content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.68262 14.9405L11.5726 10.0505C12.1501 9.47305 12.1501 8.52805 11.5726 7.95055L6.68262 3.06055' stroke='%23AD6C00' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			}
		}
	}

	&>.dashboard-menu {
		& .menu-item {
			color: $colorGray50;
			text-decoration: none;
			display: flex;
			padding: 24px 0;
			border-bottom: 1px solid #0D0D0D1A;
			width: 100%;

			& svg {
				width: 24px;
				margin-right: 12px;
			}

			&:last-child, &:first-child, &:nth-child(5) {
				border: none;
				// padding-bottom: 0;
			}

			&:last-child{
				padding-bottom: 0;
			}

			&.selected {
				color: $colorP1;
				font-weight: 700;

				&>div>svg path {
					stroke: $colorP1;
				}
			}

			&:hover {
				background: none;
			}

			&.go-permium {
				// color: $colorGray30;
				display: flex;
				justify-content: space-between;

			}

			& .permium-badge {
				background-color: $colorWarning10;
				font-size: 10px;
				font-weight: 700;
				padding: 2px 8px;
				border-radius: 12px;
				margin-right: 20px;

				& svg {
					margin: 0;
					width: 15px;
					height: 15px;
				}
			}

			& .new-badge{
				font-size: 10px;
				font-weight: 700;
				padding: 2px 8px;
				background-color: $colorPLight;
				border-radius: 12px;
				color: $colorP1;
			}
		}

		& .seperator {
			position: relative;
			color: $colorGray30;
			font-size: 14px;
			font-weight: 400;
			text-align: left;

			&::after {
				content: '';
				border-top: 1px solid $colorGray20;
				width: calc(80% - 10px);
				transform: translateX(10px);
				position: absolute;
				top: 50%;
			}
		}
	}
}