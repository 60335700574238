@import '@core/styles/_colors.scss';

.notif-container {
	display: flex;
	flex-direction: column;
	gap: 48px;
	.notif-item {
		& > .sub-header-container {
			display: flex;
			align-items: center;
			justify-content: space-between;

			& > h3,
			span {
				font-weight: 400;
				font-size: 12px;
				color: $colorGray30;
			}
		}

		& > .header {
			display: flex;
			align-items: baseline;
			gap: 8px;
			& > .unseen {
				padding: 4px;
				border-radius: 50%;
				background-color: $colorP1;
			}

			& > h1 {
				margin: 0;
				font-weight: 700;
				font-size: 16px;
				color: $colorGray50;
				& > svg {
					margin-right: 10px;
				}
			}
			margin: 8px 0;
		}

		& > .desc {
			font-weight: 400;
			font-size: 14px;
			color: $colorGray50;
		}
	}
}
