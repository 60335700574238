@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';

.toast-wrapper {
	& > div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		max-width: 552px;
		width: max-content;
		width: 100%;
		background-color: $colorGray50;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		padding: 16px;
		border-radius: 12px;
		color: $colorPureWhite;
		& > svg {
			stroke: $colorPureWhite;
			margin-left: 5px;
		}
		@media screen and (max-width: $widthTabletMax) {
			max-width: 80%;
		}
		@media screen and (max-width: $widthMobileMax) {
			max-width: 87%;
		}
	}
	&.alert {
		& > div {
			background-color: $colorAlert;
		}
	}
	&.success {
		& > div {
			background-color: $colorGray50;
		}
	}
}
